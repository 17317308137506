
import { useState, useEffect, useContext } from "react";
import { AuthContext, serverAddress, appId } from "../App";
import MenuBar from "../components/MenuBar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Dialog } from "@mui/material";
import Claim from "../components/Claim";
import back from "../assets/back.svg";
import plus from "../assets/mynft/plus.svg";
import nftImg from "../assets/nft.png";
import "./MyNFT.css";

export default function MyNFT() {

  const { state } = useContext(AuthContext);
  const [NFTList, setNFTList] = useState([]);
  const [claimOpen, setClaimOpen] = useState(false);
  const navigate = useNavigate();
  const nameList = ["《自然爱人·茶艺师》","《回忆藏家·摄影师》","《镜头哲人·导演》","《理想绘手·画家》","《未知旅人·旅行家》","《调味职人·烘培师》","《天际行者·宇航员》"];

  useEffect(() => {
    if (state.isLogin===false) {
      navigate("/");
    }
  })
  
  useEffect(() => {
    let headers = new Headers();
    headers.append("appId", appId);
    headers.append("accessToken", state.accessToken);
    fetch(serverAddress + "/user/nft", {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              setNFTList(res.data.tokenId);
            }
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [state.accessToken]);

  return (
    <div className="my-nft">
      <MenuBar />
      <div className="main mt-14">
        <div className="title-bar">
          <Link to="/" className="back"><img src={back} /></Link>
          <div className="page-title">我的NFT数字藏品馆</div>
        </div>
        <div className="contract">合约<span className="address">地址：</span>cfx:achvvn0y...46e138fnysw</div>
        <div className="grid grid-cols-2 relative">
          {NFTList.map((nft, index) => {
            return (
              <div key={index} className={"nft-wrapper item" + index}>
                <div className={"card-container item" + index + " relative"} >
                  <div className="relative flex flex-col justify-center items-center">
                    <Link to={"/nft/" + nft.tokenId} className="nft-card">
                      <img className="nft" src={"https://elements.cocafe.co/nayuki/image/card/" + nft.variety + ".png"} />
                    </Link>
                    <div className="card-title">
                      <p className="text-center text-sm font-medium name">{nameList[parseInt(nft.variety)-1]}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className={"nft-wrapper item"} onClick={()=>{setClaimOpen(true)}}>
            <div className={"card-container relative"} >
              <div className="relative flex flex-col justify-center items-center">
                <div className="nft-card flex justify-center items-center">
                  <img src={plus}></img>
                </div>
                <div className="card-title">
                  <p className="text-center text-sm font-medium name">添加NFT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notice"><p>上述NFT创意作品的知识产权由奈雪的茶拥有，NFT的所有权人或者合法拥有者不得将上述NFT创意作品用于任何商业途径以及任何非法途径。本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。</p></div>
      </div>

      <Dialog fullScreen open={claimOpen} onClose={() => {setClaimOpen(false)}} sx={{ backdropFilter: "blur(27.1828px)", "& .MuiDialog-paper": {backgroundColor:"transparent", overflow:"visible"}}}>
        <Claim handleClose={() => {setClaimOpen(false)}} />
      </Dialog>
    </div>
  )
}