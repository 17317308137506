import { AuthContext, serverAddress, appId } from "../App";
import { useContext, useEffect, useState } from "react";
import Login from "../components/Login"
import { Dialog, Drawer } from "@mui/material";
import Claim from "../components/Claim";
import MenuBar from "../components/MenuBar";
import { useNavigate } from "react-router";
import "./Home.css";

import nft from "../assets/home/home-nft.png";
import nftContainer from "../assets/home/home-nft-container.png";

export default function Home() {

  const { state } = useContext(AuthContext);
  const [loginOpen, setLoginOpen] = useState(false);
  const [claimOpen, setClaimOpen] = useState(false);
  const [claimResult, setClaimResult] = useState(false);
  const navigate = useNavigate();

  const handleLoginClose = () => {
    setLoginOpen(false);
  }
  const handleClaimClose = () => {
    setClaimOpen(false);
  }

  const continueClaim = () => {
    handleLoginClose();
    setClaimOpen(true);
  }

  const [claimed, setClaimed] = useState();
  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/user/nft", {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              if (res.data.tokenId.length > 0) {
                setClaimed(true);
              }
            }
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
    }
  },[state.isLogin])

  const claim = () => {
    if (state.isLogin) {
      if (!claimed) {
        setClaimOpen(true);
      } else {
        navigate("/mynft");
      }
    } else {
      setLoginOpen(true);
    }
  }

  return (
    <div className="home">
      <MenuBar />

      <div className="main flex flex-col items-center mt-14">
        <div className="nft-container">
          <img className="nft" src={nft} alt="nft" />
        </div>
        <button className="claim" onClick={claim}>{state.isLogin && claimed ? "我的数字藏品" : "获取你的NFT数字艺术品"}</button>
        {state.isLogin && claimed && <div onClick={() => { setClaimOpen(true) }}><p className="underline text-white mt-4 text-lg">获取更多</p></div>}
      </div>

      <div className="notice"><p>上述NFT创意作品的知识产权由奈雪的茶拥有，NFT的所有权人或者合法拥有者不得将上述NFT创意作品用于任何商业途径以及任何非法途径。本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。</p></div>

      <Drawer anchor="bottom" open={loginOpen} onClose={handleLoginClose}
        sx={{ "& .MuiDrawer-paper": { background: "black", borderRadius: "30px 30px 0px 0px" }, backdropFilter: "blur(46.2108px)" }} > 
        <div className="login-container"> <Login handler={continueClaim}/> </div>
      </Drawer>

      <Dialog fullScreen open={claimOpen} onClose={handleClaimClose}>
        <Claim handleClose={handleClaimClose}/>
      </Dialog>
    </div>
  )
}