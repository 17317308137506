import { useEffect, useState } from "react";
import useLogin from "../utils/useLogin";
import useSMS from "../utils/useSMS";
import { Checkbox } from "@mui/material";
import { Drawer } from "@mui/material";
import Agreement from "../components/Agreement";

import "./Login.css";
import { Link } from "react-router-dom";
import { fontSize } from "@mui/system";

export default function Login({ handler }) {

  const [phone, setPhone] = useState();
  const [code, setCode] = useState();

  const [phoneValidate, setPhoneValidate] = useState(false);
  const [codeValidate, setCodeValidate] = useState(false);
  
  const [btnBool, setBtnBool] = useState(false);
  const [btnText, setBtnText] = useState("获取验证码");
  const [count, setCount] = useState(0);
  
  const phoneOnchanged = (e) => {
    setPhoneValidate(false);
    setPhone(e.target.value);
  };

  const codeOnchanged = (e) => {
    setCodeValidate(false);
    setCode(e.target.value);
  };

  const sms = useSMS(phone);
  const getSMS = async () => {
    if (/^1\d{10}$/.test(phone)) {
      setBtnBool(true);
      setCount(60);
      sms().then((res) => {
        if (res === false) {
          setPhoneValidate(true);
        }
      });
    } else {
      setPhoneValidate(true);
    }
  };

  const login = useLogin(phone, code)
  const handleConfirm = () => {
    if (/^1\d{10}$/.test(phone)) {
      if (checked === true) {
        login().then((res) => {
          if (res === false) {
            setCodeValidate(true);
          } else {
            handler();
          }
        })
      }
    } else {
      console.log(checked)
      setPhoneValidate(true);
    }
  };

  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (count > 1) {
      setBtnText(count +"s");
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    if (count === 1) {
      setBtnText(count +"s");
      setTimeout(() => {
        setBtnText("获取验证码");
        setCount(0);
        setBtnBool(false);
      }, 1000);
    }
  }, [count]);

  const [agreementOpen, setAgreementOpen] = useState(false);
  
  return (
      <div className="relative" style={{padding: "0 24px"}}>
        <div className="relative">
          <div style={{padding: "34px 0 24px"}}>
            <div className="text-2xl text-white font-medium" >
              用户注册/登录
            </div>
            <div className="text-sm mt-4 text-white" style={{opacity: "0.8"}}>
              未注册过的手机号将自动创建账号
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-auto flex-col" >
              <input className="phone flex-auto" type="text" size="1" onChange={phoneOnchanged} placeholder="请输入手机号" />
              <div className="validate" style={{ height: "1.125rem", }} >
                {phoneValidate && "请输入正确的手机号"}
              </div>
            </div>
            <div className="flex flex-auto flex-col" >
              <input className="code" type="text" size="1" onChange={codeOnchanged} placeholder="请输入6位数验证码"/>
              <div className="validate" style={{ height: "1.125rem", }} >
                {codeValidate && "验证码不正确"}
              </div>
              <button onClick={getSMS} disabled={btnBool} className="login code">
                {btnText}
              </button>
            </div>
            <button className="flex-auto login next" onClick={handleConfirm} disabled={!checked}>下一步</button>
          </div>
        </div>
        
        <div className="w-full flex justify-center mt-4">
          <Checkbox onChange={handleChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: "#B5F1FE" } }} />
          <div className="text-white text-sm flex items-center">我已阅读并同意<div onClick={()=>{setAgreementOpen(true)}} className="relative" style={{color:"#B5F1FE"}}>《用户协议》</div></div>
        </div>
        
        <Drawer anchor="bottom" open={agreementOpen} onClose={()=>{setAgreementOpen(false)}}
          sx={{ "& .MuiDrawer-paper": { background: "white", borderRadius: "30px 30px 0px 0px" } }} >
          <Agreement handleClose={()=>{setAgreementOpen(false)}}/>
        </Drawer>
      </div>
  );
}
