import React, { useContext, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage, Text } from "react-konva";
import loadingBar from "../assets/poster/loading.gif";
import loadingBack from "../assets/background.png";
import { AuthContext, serverAddress, appId } from "../App";
import { useParams, useNavigate } from "react-router-dom";
import point from "../assets/gold-hand.png";
import template from "../assets/poster/poster-background.png";
import "./Poster.css";

export default function Poster() {

  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (state.isLogin === false) {
      navigate("/");
    }
  })

  const ratio = window.devicePixelRatio;
  const [deviceWidth] = useState(window.innerWidth);
  const stageRef = useRef();
  const output = useRef();
  const [nft, setNft] = useState();
  const [loaded, setLoaded] = useState(false);
  const [loadingDot, setDot] = useState("");
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [nftLoaded, setNftLoaded] = useState(false);
  const [pressed, setPressed] = useState(false);

  const [client, setOssClient] = useState(null);

  const imgWidth = 750;
  const uiWidth = 375;
  const uiHeight = 812;
  const scale = deviceWidth / uiWidth;

  const nameList = ["《自然爱人·茶艺师》","《回忆藏家·摄影师》","《镜头哲人·导演》","《理想绘手·画家》","《未知旅人·旅行家》","《调味职人·烘培师》","《天际行者·宇航员》"];
  const descriptionList = [
    "什么都见过，却什么都不争。你的年轻宇宙，应该跟随你自己的节奏，慢慢来。我是「自然爱人」，我是NAYUKI。",
    "收集人类的情绪碎片，用多彩的回忆去构建我的美好年轻宇宙。我是「回忆藏家」，我是NAYUKI。",
    "人生或许真的如戏。要用一出好戏去呈现我的美好年轻宇宙。我是「镜头哲人」，我是NAYUKI。",
    "没什么比色彩更能找回生活的温度。用色彩与线条去描绘我的美好年轻宇宙。我是「理想绘手」，我是NAYUKI。",
    "地图上有的、没有的，都是旅途的馈赠。用虔诚的好奇去历经美好年轻宇宙。我是「未知旅人」，我是NAYUKI。",
    "在人人都想冷静自持下去的时候，必须要有人用香甜的气息去唤醒美好年轻宇宙。我是「调味职人」，我是NAYUKI。",
    "对抗无穷，你要有你的无畏。要用无尽的探索去实现我的多元宇宙。我是「天际行者」，我是NAYUKI。",
  ];
  const [templateImg, setTemplate] = useState();
  const [tokenId, setTokenId] = useState();
  const [order, setOrder] = useState();
  const [owner, setOwner] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const params = useParams();

  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 19, 19);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };

  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("appId",  appId);
      headers.append("accessToken", state.accessToken);

      fetch(serverAddress + "/ststoken",{
        method: "GET",
        headers: headers,
      }).then((res) => {
        if(res.status === 200){
          res.json().then((res) => {
            if(res.returnCode === "20000"){
              const {accessKeyID,accessKeySecret,securityToken} = res.data;
              const client = new window.OSS.Wrapper({
                region:'oss-cn-shanghai',
                accessKeyId: accessKeyID,
                accessKeySecret,
                stsToken: securityToken,
                bucket: 'bucket-elements'
              })
              setOssClient(client);
            }
          })
        }
      });

      fetch(serverAddress + "/poster/"+ params.id, { method: "GET", headers: headers, })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              let url = "https://elements.cocafe.co/nayuki/image/card/" + res.data.variety + ".png";
              let nftImg = document.createElement("img");
              nftImg.crossOrigin = "anonymous";
              nftImg.src = url;
              nftImg.onload = () => {
                setNftLoaded(true);
              };
              setNft(nftImg);

              let tempImg = document.createElement("img");
              tempImg.crossOrigin = "anonymous";
              tempImg.src = template;
              tempImg.onload = () => {
                setTemplateLoaded(true);
              };
              setTemplate(tempImg);

              setTokenId(res.data.tokenId);
              setOrder(res.data.order);
              let owner = getSubStr(res.data.owner);
              setOwner(owner);
              setName(nameList[parseInt(res.data.variety)-1]);
              setDescription(descriptionList[parseInt(res.data.variety)-1]);
            }
          });
        }
      });
    }
  }, [state.isLogin]);

  useEffect(() => {
    if (nftLoaded && templateLoaded && client !== null) {
      setTimeout(() => {
        const canvas = stageRef.current.toCanvas({pixelRatio: ratio});
        let path = "nayuki/poster/" + params.id + '.jpg';
        client.list({prefix:path}).then((res) => {
          if(res.objects && res.objects.length > 0){
            let url = "https://elements.cocafe.co/" + path;
            let result = document.createElement("img");
            result.crossOrigin = "anonymous";
            result.src = url;
            output.current.appendChild(result);
            output.current.scrollTo(0,0);
            result.onload = () => {
              setLoaded(true);
            }
          }
          else {
            canvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(blob);
              reader.onload = function (event) {
                const buffer = new window.OSS.Buffer(event.target.result);
                client.put(path, buffer).then((res) => {
                  let url = "https://elements.cocafe.co/" + path;
                  let result = document.createElement("img");
                  result.crossOrigin = "anonymous";
                  result.src = url;
                  output.current.appendChild(result);
                  output.current.scrollTo(0,0);
                  result.onload = () => {
                    setLoaded(true);
                  }
                }).catch(function(err){
                  console.log(err);
                });
              }
            },'image/jpeg',1);
          }
        });
      },500);
    }
  }, [nftLoaded, templateLoaded, client]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (loadingDot.length < 3) {
        let newDot = loadingDot + ".";
        setDot(newDot);
      } else if (loadingDot.length === 3) {
        setDot("");
      }
    }, 500);
    return () => {clearTimeout(timer);}
  }, [loadingDot]);

  const handleTap = () => {
    if(loaded) setPressed(true);
  }

  return (
    <div>
      {!pressed &&(
        <div id="poster-enter" style={{ width: deviceWidth, height: window.screen.height, zIndex: 3, backgroundColor: "white" }} onClick={handleTap} onTouchStart={handleTap}>
          <img src={loadingBack} style={{ position: "fixed", top: "0", width: deviceWidth, height: (812/375)*deviceWidth }}/>
          <div id="poster-loading" style={{ position: "fixed", top: (0.5)*window.screen.height-30}}>
            { !loaded && (<img src={loadingBar} width={126} />) }
            <div className="text-sm text-white mt-5">
              { loaded ? "轻触屏幕，然后长按图片保存到本地相册" : `努力加载中${loadingDot}` }
            </div>
            {loaded &&
            <div style={{ width: "29px", animation: "blink 1.5s linear infinite", position: 'absolute', top: '70px', zIndex: 3 }} >
              <img src={point}/>
            </div>}
          </div>
        </div>
      )}
      <div style={{ position: "absolute", top: 0, left: 0, zIndex: 1,display: 'flex',alignItems:'center',justifyContent: 'center' }} ref={output}></div>

      {!loaded &&
      <Stage
        className="absolute left-0 top-0"
        width={deviceWidth}
        height={deviceWidth * (uiHeight / uiWidth)}
        ref={stageRef}
      >
        <Layer>
          <Image image={templateImg} scale={{ x: scale * (uiWidth / imgWidth), y: scale * (uiWidth / imgWidth) }} />
          <Image image={nft} width={268 * scale} height={380 * scale} x={54 * scale} y={95 * scale} />

          <Text text="收藏编码" x={137 * scale} y={67 * scale} fontSize={10  * scale} lineHeight={2 * scale} fill="black"/>

          {name==="《天际行者·宇航员》"?<Text text="NXSP" x={192 * scale} y={67 * scale} fontSize={10  * scale} lineHeight={2 * scale} fill="#B5F1FE" align="right" />
            :<Text text="NX" x={188 * scale} y={67 * scale} fontSize={10  * scale} lineHeight={2 * scale} fill="#B5F1FE" align="right" />}

          {name==="《天际行者·宇航员》"?<Text text={"6-" + order} x={222 * scale} y={67 * scale} fontSize={10  * scale} lineHeight={2 * scale} fill="white" align="right" />
            :<Text text={"1207"+tokenId} x={203 * scale} y={67 * scale} fontSize={10  * scale} lineHeight={2 * scale} fill="white" align="right" />}

          <Text text={description} width={268 * scale} x={55 * scale} y={481 * scale} fontSize={10 * scale} lineHeight={2 * scale} fontStyle="500" lineHeight={2 * scale} fill="white" align="center" />

          <Text text="数字藏品信息" x={134 * scale} y={588 * scale} fontSize={18 * scale} fontStyle="500" lineHeight={2 * scale} fill="white" />
          
          <Text text="藏品名称" x={30 * scale} y={631 * scale} fontSize={12  * scale} fill="#DCDCDC" />
          <Text text={name} width={126 * scale} x={223 * scale} y={631 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />
          
          <Text text="藏品价格" x={30 * scale} y={665 * scale} fontSize={12 * scale} fill="#DCDCDC" />
          <Text text="¥59" width={49 * scale} x={295 * scale} y={665 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />
          
          <Text text="发行者" x={30 * scale} y={699 * scale} fontSize={12 * scale} fill="#DCDCDC" />
          <Text text="奈雪的茶" width={49 * scale} x={295 * scale} y={699 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />
          
          <Text text="拥有者" x={30 * scale} y={733 * scale} fontSize={12 * scale} fill="#DCDCDC" />
          <Text text={owner} width={300 * scale}  x={44 * scale} y={733 * scale} fontSize={12 * scale} fill="#DCDCDC"  align="right" />
          
        </Layer>
      </Stage>
      }

    </div>
  );
}
