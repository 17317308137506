import { useEffect, useRef, useState } from "react";
import useClaim from "../utils/useClaim";
import { useNavigate } from "react-router-dom";
import ApngComponent from 'react-apng';
import "./Claim.css";
import title from "../assets/claim/title.png";
import cardBack from "../assets/claim/card-back.png";

export default function Claim({handleClose}) {
  const [giftCode, setGiftCode] = useState();
  const [claimState, setClaimState] = useState();
  const post = useClaim();
  const [nft, setNFT] = useState();
  const [card, setCard] = useState();
  const [nftLoaded, setNftLoaded] = useState();
  const navigate = useNavigate();
  const claimRef = useRef();
  const getNFT = () => {
    setClaimState("loading");
    post(giftCode).then((res) => {
      if (res === "fail" || res === "invalid"|| res === "used") {
        setClaimState(res);
      } else {
        setNFT(res);
        let url = "https://elements.cocafe.co/nayuki/animation/flipcard/" + res.variety + ".png";
        let nftImg = document.createElement("img");
        nftImg.crossOrigin = "anonymous";
        nftImg.src = url;
        nftImg.onload = () => {
          setNftLoaded(true);
        };
        setCard(nftImg.src);
      }
    })
  }
  const codeOnchange = (e) => {
    setGiftCode(e.target.value);
  }
  const detail = (e) => {
    navigate("/nft/" + nft.tokenId)
  }

  const [played, setPlayed] = useState(false);

  useEffect(() => {
    if (nftLoaded && played===false) {
      setClaimState("success");
      setTimeout(() => {
        claimRef.current.one();
        console.log(played)
        setPlayed(true);
      }, 1000);
    }
  },[nftLoaded])

  switch (claimState) {
    case "loading":
      return(
        <div className="claim loading">
          <div className="claim-container">
            <img className="title" src={title} />
            <img className="card-back" src="https://elements.cocafe.co/nayuki/animation/loading-back.png" />
            <div className="content">
            </div>
            <div className="control">
            </div>
          </div>
        </div>
      )
    case "success":
      return(
        <div className="success">
          <div className="success-container">
            <img className="title" src={title} />
            <ApngComponent className="card-back" ref={claimRef} rate={1} src={card}/>
            <div className="control">
              <button className={nft.variety==="7"?"to-detail special":"to-detail"} onClick={detail}>确定</button>
            </div>
          </div>
        </div>
      )
    case "fail":
      return(
        <div className="fail">
          <div className="subtitle">哎呀……空间站信号失联，<br />请保管好空间站口令</div>
          <div className="control">
            <button className="try-later" onClick={handleClose}>确定</button>
          </div>
        </div>
      )
    case "invalid":
      return(
        <div className="fail">
          <div className="subtitle">口令错误哦</div>
          <div className="control">
            <button className="re-input" onClick={()=>{setClaimState("toClaim")}}>立即重试</button>
            <button className="try-later" onClick={handleClose}>稍后重试</button>
          </div>
        </div>
      )
    case "used":
      return(
        <div className="fail">
          <div className="subtitle">口令已经失效了哦</div>
          <div className="control">
            <button className="re-input" onClick={()=>{setClaimState("toClaim")}}>立即重试</button>
            <button className="try-later" onClick={handleClose}>稍后重试</button>
          </div>
        </div>
      )
    default:
      return (
        <div className="claim">
          <div className="claim-container">
            <img className="title" src={title} />
            <img className="card-back" src={cardBack} />
            <div className="content">
              <input onChange={codeOnchange} placeholder="输入口令" />
            </div>
            <div className="control">
              <button className="confirm" onClick={(getNFT)}>确定</button>
            </div>
          </div>
        </div>
      )
  }
}